
import { CashierMessages } from '@/models/Provider';
import { defineComponent, ref, PropType, toRef, watch } from 'vue';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import Q from 'q';
import { api } from '@/services/Api';
export default defineComponent({
    name: 'ProviderCashierMessages',
    props: {
        messages: {
            type: Array as PropType<CashierMessages[]>,
            default: []
        },
        editMode: {
            type: Boolean,
            default: false
        },
        autoPrintReceiptProp: {
            type: Boolean,
            default: false
        },
        generateInvoiceProp: {
            type: Boolean,
            default: false
        },
        managerCode: {
            type: String,
            default: ''
        }
    },
    async setup(props: any, { emit }) {
        const { t } = useI18n();
        const autoPrintReceipt = toRef(props, 'autoPrintReceiptProp');
        const generateInvoice = toRef(props, 'generateInvoiceProp');
        let cashierMessages = ref<CashierMessages[]>([]);
        cashierMessages = toRef(props, 'messages');
        const localManagerCode = toRef(props, 'managerCode');
        watch(localManagerCode, (newVal) => {
            emit('changeManagerCodeSetting', newVal);
        });

        function onGenerateInvoiceChange(event: Event) {
            const newValue = (event.target as HTMLInputElement).checked;
            emit('changeGenerateInvoice', newValue);
        }

        function addMsg() {
            cashierMessages.value.push(new CashierMessages());
        }
        async function removeMsg(index: number) {
            const swalResult = await swal.fire({
                icon: 'info',
                title: t('alert.cashier.remove.message'),
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('alert.yesDeleteIt'),
                cancelButtonText: t('button.cancel')
            });
            if (swalResult.isConfirmed) {
                cashierMessages.value.splice(index, 1);
            }
        }
        async function ChangeSellingSettingAutoPrintReceipt(evt: Event) {
            evt.preventDefault();
            const swalAction = await swal.fire({
                icon: 'info',
                text: autoPrintReceipt.value
                    ? t('selling-settings.alert.disableAutoPrintReceipt')
                    : t('selling-settings.alert.enableAutoPrintReceipt'),
                customClass: {
                    confirmButton: autoPrintReceipt.value
                        ? 'stop-sale-button popup-bookit-button my-1 px-4'
                        : 'green-button popup-bookit-button my-1 px-4',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: autoPrintReceipt.value ? t('alert.yesDeactivate') : t('alert.yesActivate'),
                cancelButtonText: t('button.cancel')
            });
            if (!swalAction.isConfirmed) {
                return;
            }
            await Q.delay(200);
            swal.showLoading();
            const apiPromise = api.changeAutoPrintReceiptProviderSetting(!autoPrintReceipt.value);
            await Q.delay(400);
            const apiResult = await apiPromise;
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            if (apiResult.data) {
                emit('changeReceiptProviderSetting', apiResult.data.newValue);
                await swal.fire({
                    position: 'center',
                    icon: 'success',
                    showConfirmButton: true
                });
            }
        }
        return { 
            cashierMessages, 
            addMsg, 
            removeMsg, 
            autoPrintReceipt,
            ChangeSellingSettingAutoPrintReceipt, 
            localManagerCode,
            generateInvoice,
            onGenerateInvoiceChange
        };
    }
});
